import React from "react";
import Layout from "src/components/common/Layout";
import { getAgilityPageProps, getAgilityPaths } from "@agility/nextjs/node";
import createApi from "src/helpers/api";
import options from "src/components/agility-pageModules/AgilityMiddleware";
import Header from "src/components/common/Header/Header";
const agility = require("@agility/content-fetch");
import {
  tranformSitemapNested,
  transformToLocaleHref,
} from "src/helpers/utils";

// getStaticProps function fetches data for all of your Agility Pages and Next.js will pre-render these pages at build time
export async function getStaticProps({ preview, params, locale }) {
  let _locale = locale == "default" ? "en" : locale;
  let agilityProps = {};
  let currentUrlSlug = "/";
  let slugArr = params?.slug;
  currentUrlSlug += slugArr?.join("/");

  // Set up agility api
  const isPreview = preview || process.env.IS_PREVIEW == "true";
  const apiKey = !!isPreview
    ? process.env.AGILITY_API_PREVIEW_KEY
    : process.env.AGILITY_API_FETCH_KEY;
  const api = createApi(process.env.AGILITY_GUID, apiKey, isPreview);

  // Get Sitemap
  const agilitySitemap = process.env.AGILITY_SITEMAP;
  let sitemapFlat = await api.getSitemap(agilitySitemap, _locale);
  let sitemapNested = await api.getSitemapNested(agilitySitemap, _locale);
  let thisSiteMapItem = sitemapFlat[currentUrlSlug];

  if (!!thisSiteMapItem?.redirect) {
    let toRedirect = thisSiteMapItem.redirect;
    let redirectLocation = toRedirect.url.replace("~", "");
    console.log("is redirecting to: ", redirectLocation);
    return {
      redirect: {
        destination: redirectLocation,
        permanent: false,
      },
    };
  }

  // place all global here
  const globalComponents = {
    header: Header,
  };

  try {
    agilityProps = await getAgilityPageProps({
      preview: isPreview,
      params,
      locale: _locale,
      getModule: () => {},
      defaultLocale: process.env.AGILITY_DEFAULT_LOCALE,
      globalComponents,
    });
  } catch (error) {
    console.log({ error });
    agilityProps = { notFound: true };
  }

  agilityProps["sitemap"] = sitemapFlat;
  agilityProps["sitemapNested"] = tranformSitemapNested(
    sitemapNested,
    agilityProps.sitemapNode
  );

  if (!agilityProps.notFound) {
    // Get Data Definitions for global Labels
    const globalLanguageResources = await api.getContentList(
      "globalLanguageResources",
      0,
      50,
      [],
      _locale
    );
    agilityProps["labels"] = globalLanguageResources.items;

    // Handle middleware
    let zones = agilityProps.page?.zones || null;
    if (!!zones) {
      await Promise.all(
        Object.keys(zones).map(async (zoneKey, zoneIndex) => {
          let zone = zones[zoneKey];
          await Promise.all(
            zone.map(async (agilityModule, moduleIndex) => {
              if (!!agilityModule.item) {
                if (!!agilityModule.item.fields) {
                  agilityModule.item.fields["locale"] = _locale;
                }
                if (!!options.moduleHandlers[agilityModule.moduleName]) {
                  await options.moduleHandlers[agilityModule.moduleName](
                    agilityModule,
                    agilityProps,
                    api,
                    _locale
                  );
                }
              }
            })
          );
        })
      );
    }
  }

  const _api = agility.getApi({
    guid: process.env.AGILITY_GUID,
    apiKey: process.env.AGILITY_API_FETCH_KEY,
  });

  let redirects = await _api.getUrlRedirections({});

  // replace all undefined with null to avoid data serialization errors & modified href value to have locale sub path
  let _agilityProps = JSON.parse(
    JSON.stringify(agilityProps, (key, value) => {
      if (key == "href") {
        return transformToLocaleHref(value, _locale);
      } else {
        return value === undefined ? null : value;
      }
    })
  );

  return {
    // return all props
    props: { ..._agilityProps, redirects },

    // Next.js will attempt to re-generate the page when a request comes in, at most once every 10 seconds
    // Read more on Incremental Static Regenertion here: https://nextjs.org/docs/basic-features/data-fetching#incremental-static-regeneration
    revalidate: 10,
  };
}

// Next.js will statically pre-render all the paths from Agility CMS
export async function getStaticPaths() {
  //get the paths configured in agility
  let agilityPaths = await getAgilityPaths({
    preview: false,
    locales: process.env.AGILITY_LOCALES?.split(","),
    defaultLocale: process.env.AGILITY_DEFAULT_LOCALE,
  });

  return {
    paths: agilityPaths,
    fallback: true,
  };
}

const AgilityPage = (props) => {
  return <Layout {...props} />;
};

export default AgilityPage;
